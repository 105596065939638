export const links = [
  {
    id: 1,
    url: "https://opensea.io/collection/carrotperson",
    text: "OpenSea",
  },
  {
    id: 2,
    url: "https://twitter.com/CarrotPersonYC",
    text: "Twitter",
  },
  {
    id: 3,
    url: "https://discord.gg/8wAZBFdN",
    text: "Discord",
  },
];
